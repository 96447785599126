/** --- PageLayout --- **/
.PageLayout {
  --pf-c-page__header--MinHeight: 4rem;
  --pf-c-page__header-tools--MarginRight: 0.25rem;
  --pf-c-page__header-tools--MarginLeft: 0;
}

.PageLayout .pf-c-breadcrumb {
  min-height: 2.5rem;
}

.PageLayout .PageSection {
  flex-grow: 1;
}

.PageLayout .PageFooter {
  background-color: rgb(0, 64, 128);
  color: white;
  padding: 0 1rem;
  min-height: 2.5rem;
}

.PageLayout .PageFooter a {
  color: white;
  cursor: pointer;
}

.PageLayout .PageFooter a:hover {
  color: #deb02e;
  text-decoration: none;
}

.PageLayout .PageFooter a:hover svg path {
  fill: #deb02e;
}

.PageLayout .PageFooter svg path {
  fill: white;
}
/** --- ---------- --- **/
