/** Chip **/
.Chip.Chip__variant-green {
  background-color: var(--pf-global--palette--green-500);
}

.Chip.Chip__variant-green .pf-c-chip__text {
  color: white;
}

.Chip.Chip__variant-red {
  background-color: red;
  color: white;
}
/** ---- **/