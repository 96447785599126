.RemotoInterfaces .pf-c-tab-content {
  padding-top: 1rem;
}

.RemotoInterfaces__iframe-container {
  height: 12rem;
}

.RemotoInterfaces__iframe-container iframe {
  width: 100%;
  height: 12rem;
}

.RemotoInterfaces__tabs__overlap {
  margin-bottom: 0.5rem;
}