:root {
  --pf-c-tabs__link--focus--after--BorderWidth: 0px !important;
  --pf-c-tabs__link--after--BorderWidth: 0px !important;
}

#root {
  height: 100%;
}

header.pf-c-page__header {
  background-color: var(--pf-global--primary-color--200)
}
span.rs-loader-spin:after {
  border-top-color: var(--pf-global--palette--blue-300)
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 8px !important;
}

/** Toolbar **/
.pf-c-toolbar.project__custom-toolbar {
  --pf-c-toolbar--BackgroundColor: var(--pf-c-page__header-nav--BackgroundColor);
  --pf-c-toolbar--RowGap: 0;
}

.--seccion-principal {
  max-width: calc(100%-10rem);
  overflow: scroll;
}
/** --- **/


/** Navbar **/
.pf-c-page__main-section.pf-m-light {
  padding: 0.1rem 0.1rem 0.1rem 0.1rem;
}

#page-sidebar {
  width: 10rem;
}

.pf-c-toolbar.project__custom-toolbar {
  --pf-c-toolbar--RowGap: 0;
}

.pf-c-nav__link.pf-m-current::after {
  border-color: white !important;
}
/** --- **/

/** Label **/
.pf-c-label.LabelSuccess,
.pf-c-label.LabelWarning,
.pf-c-label.LabelDanger {
  width: 40px;
  display: flex;
  justify-content: center;
}

.pf-c-label.LabelSuccess {
  background-color: #0C8346;
}

.pf-c-label.LabelWarning {
  background-color: #FF1D15;
}

.pf-c-label.LabelDanger {
  background-color: #F5F5F5;
}

.pf-c-label.LabelSuccess > .pf-c-label__content,
.pf-c-label.LabelWarning > .pf-c-label__content {
  color: white;
}
/** --- **/
/** Notifications **/
.pf-c-alert.Notification {
  padding: 0.5rem;
}

.pf-c-alert.Notification > .pf-c-alert__title {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}
/** --- **/
/** Page **/
.Page {
  padding: 1rem;
}

.Page .pf-c-card {
  font-size: 18px;
}
/** --- **/

/** Breadcrumb **/
.pf-c-breadcrumb {
  min-height: 50px;
}
/** --- **/

/** Table **/
table.pf-c-table tr > *.hidden {
  --pf-hidden-visible--Display: none;
  display: var(--pf-hidden-visible--Display);
}
/** --- **/

/** Divider **/
hr.pf-c-divider {
  margin: 16px 0;
}
/** --- **/

/** Tabs **/
.pf-c-tabs__link,
.pf-c-tab-content,
.pf-c-card__body {
  outline: none !important;
}
/** --- **/

/** Data List Title **/
.pf-c-title.pf-m-lg.DataListTitle {
  padding-left: 0.5rem;
  margin-bottom: 0.5rem;
}
/** --- **/

/** Badge **/
.pf-c-badge.green {
  --pf-c-badge--m-unread--BackgroundColor: var(--pf-global--palette--green-500);
}

.pf-c-badge.yellow {
  --pf-c-badge--m-unread--BackgroundColor: var(--pf-global--palette--gold-200);
}

.pf-c-badge.red {
  --pf-c-badge--m-unread--BackgroundColor: var(--pf-global--palette--red-200);
}

.pf-c-badge.grey {
  --pf-c-badge--m-unread--BackgroundColor: var(--pf-global--Color--200);
}

.pf-c-badge.purple {
  --pf-c-badge--m-unread--BackgroundColor: var(--pf-global--palette--purple-200);
}
/** --- **/