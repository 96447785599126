/** RemotosTable **/
.RemotosTable.pf-c-table tbody > tr > * {
  vertical-align: middle;
}

.RemotosTable th[data-label="Equipo"] {
  padding-left: 14px;
}

.RemotosTable td[data-label="Equipo"] {
  padding: 0;
  width: 220px;
}

.RemotosTable .pf-c-data-list__item-row {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.RemotosTable .pf-c-data-list__item-content > .pf-c-data-list__cell:first-child {
  max-width: 50px;
  position: relative;
  text-align: right;
  font-weight: bold;
  color: var(--pf-global--Color--200)
}

.RemotosTable .pf-c-data-list__item-content > .pf-c-data-list__cell:first-child::after {
  content: ":";
  position: absolute;
  right: -5px;
}
/** ------------ **/